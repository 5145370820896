<template>
  <g>
    <template v-if="small">
      <rect
        x="26.5"
        y="26.5"
        width="75"
        height="75"
        rx="11.5"
        stroke="var(--color, black)"
        stroke-width="5"
        id="rect2"
        style="stroke-width:11;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0"
      />
      <rect
        x="45.067772"
        y="45.067772"
        width="38.111031"
        height="38.111031"
        rx="3.3627379"
        :style="{
          stroke: 'var(--color, black)',
          fill: solid ? 'var(--color, black)' : 'none'
        }"
        stroke-width="4.96786"
        id="rect4"
      />
      <path
        d="m 22.5,37.821201 h 2 v 11 h -2 z m -17.99983,11 c -1.10456,0 -2.8834356,-2.462623 -2.8834356,-5.500004 0,-3.037648 1.7788756,-5.499996 2.8834356,-5.499996 z m 17.99983,0 H 4.50017 v -11 H 22.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7"
        style="stroke-width:1.6583"
      />
      <path
        d="m 22.5,79.178802 h 2 v 11 h -2 z m -17.9998301,11 c -1.10456,0 -2.8834356,-2.462623 -2.8834356,-5.500004 0,-3.037648 1.7788756,-5.499996 2.8834356,-5.499996 z m 17.9998301,0 H 4.5001699 v -11 H 22.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1"
        style="stroke-width:1.6583"
      />
      <path
        d="m 22.5,59.5 h 2 v 11 h -2 z m -17.9998304,11 c -1.10456,0 -2.883435,-2.462623 -2.883435,-5.500004 0,-3.037648 1.778875,-5.499996 2.883435,-5.499996 z M 22.5,70.5 H 4.5001696 v -11 H 22.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-56"
        style="stroke-width:1.6583"
      />
      <path
        d="m 105.5,37.821201 h -2 v 11 h 2 z m 17.99983,11 c 1.10456,0 2.88344,-2.462623 2.88344,-5.500004 0,-3.037648 -1.77888,-5.499996 -2.88344,-5.499996 z m -17.99983,0 h 17.99983 v -11 H 105.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-8"
        style="stroke-width:1.6583"
      />
      <path
        d="m 105.5,79.178802 h -2 v 11 h 2 z m 17.99983,11 c 1.10456,0 2.88344,-2.462623 2.88344,-5.500004 0,-3.037648 -1.77888,-5.499996 -2.88344,-5.499996 z m -17.99983,0 h 17.99983 v -11 H 105.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5"
        style="stroke-width:1.6583"
      />
      <path
        d="m 48.821201,105.50291 v -2 h -11 v 2 z m -11,17.99983 c 0,1.10456 2.46263,2.88344 5.50001,2.88344 3.03765,0 5.49999,-1.77888 5.49999,-2.88344 z m 0,-17.99983 v 17.99983 h 11 v -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5-7"
        style="stroke-width:1.6583"
      />
      <path
        d="m 90.178802,23.5 v 2 h -11 v -2 z m -11,-17.999831 c 0,-1.10456 2.46262,-2.88344 5.5,-2.88344 3.03765,0 5.5,1.77888 5.5,2.88344 z m 0,17.999831 V 5.500169 h 11 V 23.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-8-1-9"
        style="stroke-width:1.6583"
      />
      <path
        d="m 48.821201,23.5 v 2 H 37.821204 v -2 z M 37.821204,5.500169 c 0,-1.10456 2.46263,-2.88344 5.50001,-2.88344 3.037647,0 5.499987,1.77888 5.499987,2.88344 z m 0,17.999831 V 5.500169 H 48.821201 V 23.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5-7-6"
        style="stroke-width:1.6583"
      />
      <path
        d="m 90.178802,105.50291 v -2 h -11 v 2 z m -11,17.99983 c 0,1.10456 2.46263,2.88344 5.50001,2.88344 3.03765,0 5.49999,-1.77888 5.49999,-2.88344 z m 0,-17.99983 v 17.99983 h 11 v -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5-7-60"
        style="stroke-width:1.6583"
      />
      <path
        d="m 69.6,105.50291 v -2 h -11 v 2 z m -11,17.99983 c 0,1.10456 2.46263,2.88344 5.50001,2.88344 3.03765,0 5.49999,-1.77888 5.49999,-2.88344 z m 0,-17.99983 v 17.99983 h 11 v -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5-7-2"
        style="stroke-width:1.6583"
      />
      <path
        d="m 69.6,23.5 v 2 h -11 v -2 z M 58.6,5.50017 c 0,-1.10456 2.46263,-2.88344 5.50001,-2.88344 3.03765,0 5.49999,1.77888 5.49999,2.88344 z M 58.6,23.5 V 5.50017 h 11 V 23.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-5-7-2-2"
        style="stroke-width:1.6583"
      />
      <path
        d="m 105.61674,59.5 h -2.00001 v 11 h 2.00001 z m 17.99983,11 C 124.72113,70.5 126.5,68.037377 126.5,64.999996 126.5,61.962348 124.72113,59.5 123.61656,59.5 Z m -17.99983,0 h 17.99983 v -11 h -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9-5-7-1-56-9"
        style="stroke-width:1.6583"
      />
    </template>
    <template v-else>
      <rect
        x="26.5"
        y="26.5"
        width="75"
        height="75"
        rx="11.5"
        stroke="var(--color, black)"
        stroke-width="5"
        id="rect2"
      />
      <rect
        x="38.189465"
        y="38.189465"
        width="51.621075"
        height="51.621075"
        rx="4.5548005"
        :style="{
          stroke: 'var(--color, black)',
          fill: solid ? 'var(--color, black)' : 'none'
        }"
        stroke-width="5"
        id="rect4"
      />
      <path
        d="m 41.821201,23.50017 v 2 h 6 v -2 z m 6,-8 c 0,-1.1046 -1.3431,-2 -3,-2 -1.6569,0 -3,0.8954 -3,2 z m 0,8 v -8 h -6 v 8 z"
        fill="var(--color, black)"
        id="path14"
        style="stroke-width:1.22474"
      />
      <path
        d="m 54.1667,23.5 v 2 h 6 v -2 z m 6,-17.99983 c 0,-1.10457 -1.34325,-2 -3,-2 -1.6569,0 -3,0.89543 -3,2 z m 0,17.99983 V 5.50017 h -6 V 23.5 Z"
        fill="var(--color, black)"
        id="path16"
        style="stroke-width:1.22474"
      />
      <path
        d="m 86.178802,23.5 v 2 h -6 v -2 z m -6,-8 c 0,-1.1046 1.3431,-2 3,-2 1.6569,0 3,0.8954 3,2 z m 0,8 v -8 h 6 v 8 z"
        fill="var(--color, black)"
        id="path14-3"
        style="stroke-width:1.22474"
      />
      <path
        d="m 73.833304,23.499829 v 2 h -6 v -2 z m -6,-17.9998295 c 0,-1.10457 1.343249,-2 3,-2 1.6569,0 3,0.89543 3,2 z m 0,17.9998295 V 5.4999995 h 6 V 23.499829 Z"
        fill="var(--color, black)"
        id="path16-9"
        style="stroke-width:1.22474"
      />
      <path
        d="m 41.821201,105.49983 v -2 h 6 v 2 z m 6,8 c 0,1.1046 -1.3431,2 -3,2 -1.6569,0 -3,-0.8954 -3,-2 z m 0,-8 v 8 h -6 v -8 z"
        fill="var(--color, black)"
        id="path14-6"
        style="stroke-width:1.22474"
      />
      <path
        d="m 54.1667,105.5 v -2 h 6 v 2 z m 6,17.99983 c 0,1.10457 -1.34325,2 -3,2 -1.6569,0 -3,-0.89543 -3,-2 z m 0,-17.99983 v 17.99983 h -6 V 105.5 Z"
        fill="var(--color, black)"
        id="path16-7"
        style="stroke-width:1.22474"
      />
      <path
        d="m 86.178801,105.5 v -2 h -6 v 2 z m -6,8 c 0,1.1046 1.3431,2 3,2 1.6569,0 3,-0.8954 3,-2 z m 0,-8 v 8 h 6 v -8 z"
        fill="var(--color, black)"
        id="path14-3-5"
        style="stroke-width:1.22474"
      />
      <path
        d="m 73.833301,105.50017 v -2 h -5.999997 v 2 z M 67.833304,123.5 c 0,1.10457 1.343247,2 2.999997,2 1.6569,0 3,-0.89543 3,-2 z m 0,-17.99983 V 123.5 h 5.999997 v -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9"
        style="stroke-width:1.22474"
      />
      <path
        d="m 105.49983,86.178806 h -2 v -6 h 2 z m 8,-6 c 1.1046,0 2.00001,1.3431 2.00001,3 0,1.6569 -0.89541,3 -2.00001,3 z m -8,0 h 8 v 6 h -8 z"
        fill="var(--color, black)"
        id="path14-6-6"
        style="stroke-width:1.22474"
      />
      <path
        d="m 105.5,73.833306 h -2 v -6 h 2 z m 17.99984,-6 c 1.10456,0 2,1.34325 2,3 0,1.6569 -0.89544,3 -2,3 z m -17.99984,0 h 17.99984 v 6 H 105.5 Z"
        fill="var(--color, black)"
        id="path16-7-5"
        style="stroke-width:1.22474"
      />
      <path
        d="m 105.5,41.821201 h -2 v 6 h 2 z m 8,6 c 1.1046,0 2,-1.3431 2,-3 0,-1.6569 -0.8954,-3 -2,-3 z m -8,0 h 8 v -6 h -8 z"
        fill="var(--color, black)"
        id="path14-3-5-2"
        style="stroke-width:1.22474"
      />
      <path
        d="m 105.50017,54.166701 h -2 v 6.000005 h 2 z M 123.5,60.166706 c 1.10456,0 2,-1.34325 2,-3.000005 0,-1.6569 -0.89544,-3 -2,-3 z m -17.99983,0 H 123.5 v -6.000005 h -17.99983 z"
        fill="var(--color, black)"
        id="path16-9-9-5"
        style="stroke-width:1.22474"
      />
      <path
        d="m 22.50034,86.178805 h 2 v -6 h -2 z m -8,-6 c -1.1046,0 -2.00001,1.3431 -2.00001,3 0,1.6569 0.89541,3 2.00001,3 z m 8,0 h -8 v 6 h 8 z"
        fill="var(--color, black)"
        id="path14-6-6-7"
        style="stroke-width:1.22474"
      />
      <path
        d="m 22.50017,73.833305 h 2 v -6 h -2 z m -17.99984,-6 c -1.10456,0 -2,1.34325 -2,3 0,1.6569 0.89544,3 2,3 z m 17.99984,0 H 4.50033 v 6 h 17.99984 z"
        fill="var(--color, black)"
        id="path16-7-5-4"
        style="stroke-width:1.22474"
      />
      <path
        d="m 22.50017,41.8212 h 2 v 6 h -2 z m -8,6 c -1.1046,0 -2,-1.3431 -2,-3 0,-1.6569 0.8954,-3 2,-3 z m 8,0 h -8 v -6 h 8 z"
        fill="var(--color, black)"
        id="path14-3-5-2-4"
        style="stroke-width:1.22474"
      />
      <path
        d="m 22.5,54.1667 h 2 v 6.000005 h -2 z M 4.50017,60.166705 c -1.10456,0 -2,-1.34325 -2,-3.000005 0,-1.6569 0.89544,-3 2,-3 z m 17.99983,0 H 4.50017 V 54.1667 H 22.5 Z"
        fill="var(--color, black)"
        id="path16-9-9-5-7"
        style="stroke-width:1.22474"
      />
    </template>
  </g>
</template>
<script>
export default {
  name: "IconDevice",
  props: {
    solid: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>
